/**
 * Mobile Navigation handler
 *
 * @author         Flurin Dürst
 * @version 	   1.02
 * @since 		   WPegg 0.12
 *
 */

$(function(){
    $(document).on('click', '#mobilenav_trigger', function (event) {
        // show overlay
        $('#nav_mobile').toggleClass('menu_hidden');
        // switch icon
        $('#mobilenav_trigger').toggleClass('close');
        $('html').toggleClass('noscroll');
    });
});

/* ADD LIGHTBOX TO IMAGES @ PRODUKTE
/-----------------------------------*/
$(function(){
	$('.content.products section a[rel]').attr('data-lightbox', 'produktbild');
	$('.content.products section a[rel]').attr('data-title', '');
});
